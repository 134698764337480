import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { userService } from 'services/user.service';
import SEO from 'utils/seo';
import games from "../assets/img/game.jpg";
import logo from "../assets/img/logo.svg";
import { Col, Container, Row } from "reactstrap"; // Import the logo as the placeholder


/*
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const FunkyGraph = ({ contentUploadDatas }) => {
    let contentUploadData = { 'Sun': 7, 'Mon': 2, 'Tue': 10, 'Wed': 6, 'Thur': 4, 'Fri': 10, 'Sat': 2, 'Sund': 12 };
    const backgroundColors = ['#FF6347', '#4682B4', '#FFD700', '#32CD32', '#FF4500', '#36A2EB', '#6A5ACD', '#FF6384', '#48D1CC', '#FF1493'];
    const data = {
        labels: Object.keys(contentUploadData),
        datasets: [
            {
                //          label: 'Players Joined',
                data: Object.values(contentUploadData),
                backgroundColor: backgroundColors,
                borderColor: backgroundColors,
                borderWidth: 2,
                borderRadius: 20,
                barThickness: 40,
                hoverBackgroundColor: '#FFF700',
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: '#FFFFFF',
                    font: {
                        size: 16,
                        family: "'Comic Sans MS', cursive, sans-serif",
                    }
                }
            },
            title: {
                display: true,
                text: 'Latest Content Uploads',
                color: '#FFFFFF',
                font: {
                    size: 24,
                    family: "'Comic Sans MS', cursive, sans-serif"
                }
            },
            datalabels: {
                color: '#FFFFFF',
                anchor: 'end', // Position the label on top of the bars
                align: 'end',  // Align it to the top center of the bar
                font: {
                    size: 14,
                    family: "'Comic Sans MS', cursive, sans-serif",
                },
                formatter: (value) => value // Display the actual value
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#FFFFFF',
                    font: {
                        size: 14,
                        family: "'Comic Sans MS', cursive, sans-serif"
                    }
                },
                grid: {
                    display: false
                }
            },
            y: {
                display: false, // Hide the left Y-axis
                ticks: {
                    beginAtZero: true,
                },
                grid: {
                    display: false, // Hide Y-axis grid lines for cleaner look
                }
            }
        }
    };

    return (
        <div style={{
            backgroundColor: '#1A1A1A',
            padding: '20px',
            borderRadius: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            maxWidth: '800px',
            margin: '0 auto',
        }}>
            <Bar data={data} options={options} />
        </div>
    );
}

const instaEmbed = (embed_url, index) => {
    let abcd = embed_url + "/embed/?utm_source=ig_embedembed%2F%3Fc%3D1%3B";
    let id = "instagram-embed-" + index;
    let dataInstagramPayloadId = "instagram-media-payload-" + index;;
    return (
        <>
            <a href={embed_url} target="_blank" rel="noopener noreferrer">
                <iframe className="instagram-media instagram-media-rendered"
                    loading="lazy"
                    style={{ pointerEvents: "none" }}
                    id={id}
                    data-src={abcd}
                    src={abcd} allowtransparency="true" width="150" height="150" frameBorder="0"
                    data-instagram-payload-id={dataInstagramPayloadId}
                    onClick={() => window.open(embed_url, '_blank')}

                >


                </iframe>
            </a>
        </>
    )
}
*/

const CreatorProfile = ({ }) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    const { social_media_handle } = useParams();
    const [creator, setCreator] = useState({ 'posts': [] });
    const [isImageLoaded, setIsImageLoaded] = useState(false); // State to track image load
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        userService.getCreatorDetails(social_media_handle)
            .then((res) => setCreator(res))
            .catch((err) => {});
    }, [social_media_handle]);

    const handleBack = () => {
        // Always try navigating back
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/'); // Fallback to homepage if no history
        }
    };

    return (
        <div>
            <SEO
                title={"Discover " + creator.name + " | Con10Craze Creator Profile, Stats, and Social Links"}
                description={"Explore the official profile of " + creator.name + ", a top Fantasy Sports Creator on Con10Craze. View their stats, including followers, average likes, and comments. Check out their wins and games played, and connect with them on Instagram for more exciting content!"}
                type="website"
                url={"https://www.con10craze.com/creator/" + creator.name}
            />

            <div className="wrapper" style={{
                backgroundImage: `url(${games})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'relative'
            }}>
                <Container>
                    <Row>
                        <Col className='ml-auto mr-auto'>

                            {/* Back Button */}
                            <button className="back-button-funky" onClick={handleBack}
                                style={{ position: 'absolute', top: '20px', left: '20px', zIndex: 10 }}>
                                &larr; Back
                            </button>

                            <div className="creator-profile-funky" style={{ marginTop: "50px" }}>
                                <div className="profile-header-funky">
                                    {/* Use placeholder logo until the image loads */}
                                    <img
                                        src={isImageLoaded ? creator.profile_img : logo} // Show actual image once loaded
                                        alt={`${creator.name}'s profile`}
                                        className="profile-pic-funky"
                                        onLoad={() => setIsImageLoaded(true)} // When image loads, set state to true
                                    />
                                    <div className="profile-info-funky">
                                        <h2>{creator.name}</h2>
                                        <h4>(Creator)</h4>
                                        <div className="profile-stats-funky">
                                            <span style={{ backgroundColor: "green" }}>{formatter.format(creator.followers)} Followers</span>
                                            <span style={{ backgroundColor: "green" }}>{formatter.format(creator.avg_likes)} Likes (Avg)</span>
                                            <span style={{ backgroundColor: "green" }}>{formatter.format(creator.avg_comments)} Comments (Avg)</span>
                                        </div>
                                    </div>
                                </div>

                                <Container className="profile-stats-extra-funky">
                                    <Row>
                                        <Col className="text-center ml-auto mr-auto stat-item-funky" xs="6" >
                                            <div className="stat-icon-wrapper">
                                                <i className="fas fa-gamepad stat-icon-funky"></i> {/* Icon for Games Played */}
                                            </div>
                                            <span className="stat-number-funky">{creator.games_played}</span>
                                            <span className="stat-description-funky">Games Played</span>
                                        </Col>
                                        <Col className="text-center ml-auto mr-auto stat-item-funky" xs="6">
                                            <div className="stat-icon-wrapper">
                                                <i className="fas fa-trophy stat-icon-funky"></i> {/* Icon for Games Won */}
                                            </div>
                                            <span className="stat-number-funky">{creator.games_won}</span>
                                            <span className="stat-description-funky">Games Won</span>
                                        </Col>
                                    </Row>
                                </Container>

                                <div className="instagram-link-funky">
                                    <a href={"https://instagram.com/" + creator.social_media_handle} target="_blank"
                                        rel="noopener noreferrer" className="instagram-btn-funky">
                                        View Instagram Profile
                                    </a>
                                </div>

{/*
                                <FunkyGraph />

                                <Col className='ml-auto mr-auto text-center'>
                                    <h3 style={{ backgroundColor: '#FF4500', padding: '10px', color: 'white' }}>Latest Content Analysis</h3>
                                    {creator["posts"].map((post, index) => (
                                        <div key={index} style={{ display: 'flex', flexDirection: "column", marginBottom: '20px', padding: '10px', backgroundColor: '#F0F0F0', borderRadius: '10px' }}>
                                            <Row>
                                                <div>
                                                <div key={index} className="insights-grid-item" 
                                                    style={{ width: '150px', height: '150px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(1, 1, 1, 1)', padding: "0px" }}>
                                                {instaEmbed(post.embed_url, index)}
                                            </div>
                                                </div>

                                                <div style={{ marginLeft: '20px', flex: 1 }}>
                                                    <p style={{ margin: '0', fontWeight: 'bold', fontSize: '18px' }}>post.caption</p>
                                                    <span style={{ margin: '5px 0', color: '#555' }}>{post.post_timestamp}</span>
                                                    <p style={{ margin: '5px 0', color: '#555' }}>Views: post.views</p>
                                                </div>
                                            </Row>

                                            <Row style={{ marginTop: '10px' }}>
                                                <Col className='ml-auto mr-auto text-center' xs="4">
                                                    <span>Likes(1pt)</span><br></br>
                                                    <span>{post.num_likes}</span>
                                                </Col>
                                                <Col className='ml-auto mr-auto text-center' xs="4">
                                                    <span>Comments(2pt)</span><br></br>
                                                    <span>{post.num_comments}</span>
                                                </Col>
                                                <Col className='ml-auto mr-auto text-center' xs="4">
                                                    <span>Score</span><br></br>
                                                    <span>{post.score}</span>
                                                </Col>
                                            </Row>

                                        </div>
                                    ))}
                                </Col>
*/}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default CreatorProfile;
