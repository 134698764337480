import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({ title, description, type, url, image }) {
    if (image === undefined || image === null) {
        // TODO: Change to new cloudfront for Con10Craze
        image = "https://d78eqrbdvopvl.cloudfront.net/logo.png";
    }
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='title' content={title} />
            <meta name='description' content={description} />
            <meta name="application-name" content="Con10Craze"/>
            { /* End standard metadata tags */}

            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content="Con10Craze"/>
            <meta property="og:url" content={url} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            { /* End Facebook tags */}

            { /* Twitter tags */}
            <meta name="twitter:creator" content="Con10Craze" />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta property="twitter:url" content={url} />
            <meta name="twitter:site" content="@con10craze" />
            <meta name="twitter:image" content={image} />

            { /* End Twitter tags */}
        </Helmet>
    )
}