import { Col, Container, Row } from 'reactstrap';
import SEO from '../utils/seo';
import { useNavigate } from 'react-router-dom';
import { userService } from "services/user.service.js";
import { SignupBonus } from 'utils/constants';
import { ReferralBonus } from 'utils/constants';
import React from "react";

const promotions = [
    { id: 1, color: 'linear-gradient(135deg, #f6d365 0%, #fda085 100%)', icon: '💰', text: 'Sign-Up Bonus', value: `₹${SignupBonus}`},
    { id: 2, color: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)', icon: '🎁', text: 'Referral Reward', value: `₹${ReferralBonus} per referral *` },
    { id: 3, color: 'linear-gradient(135deg, #f093fb 0%, #f5576c 100%)', icon: '🏆', text: 'Daily Streaks', value: '₹50 every week'},
    { id: 4, color: 'linear-gradient(135deg, #c2e9fb 0%, #81a4fd 100%)', icon: '🎉', text: 'Coins', value: 'Unlimited' }
];

const Promotions = () => {
    const navigate = useNavigate();


    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

    })

    const handlePromotionClicked = (e, id) => {
        e.preventDefault();
        if (id === 1) {
            // eslint-disable-next-line no-lone-blocks
            {!userService.isLoggedIn() && navigate('/signup')};
        } else if (id === 2) {
            navigate('/profile', { state: { 'activeTab': 1 } });
        } else if (id === 3) {
            navigate('/profile', { state: { 'activeTab': 0 ,  scrollToStreaks: true} });
        } else if (id == 4) {
            navigate('/coins');
        }
    }

    return (
        <div className="section section-basic" id="basic-elements">
            <SEO
                title="Con10Craze Exclusive Promotions - Earn up to INR 1 Lakh referral bonus"
                description="Get rewarded for your loyalty! Explore our platform and unlock exclusive benefits, discounts, rewards and promotions. Join our rewards program now!"
                type="website"
                url="https://www.con10craze.com/promotions" />


            <Container>
                <Row>
                    <Col className="ml-auto mr-auto pull-left text-center">
                        <h2 className="title">Con10Craze Promotions and Rewards</h2>
                        <p>Welcome to the Con10Craze Promotions page! We're thrilled to offer exciting rewards, promotions and bonuses to enhance your experience in the world of fantasy sports games. Keep an eye on this page for updates on ongoing and upcoming rewards that could add an extra layer of fun to your Con10Craze journey.</p>
                        {/*<h2 style={{ marginTop: "50px" }}>Current Promotions</h2>*/}
                    </Col>
                </Row>




                <div className="promotions-container">
                    {promotions.map(promo => (
                        <div key={promo.id} className="promotion-box" style={{ background: promo.color }} onClick={(e) => handlePromotionClicked(e, promo.id)}>
                            <div className="promotion-icon">{promo.icon}</div>
                            <div className="promotion-text">{promo.text}</div>
                            <div className="promotion-value">{promo.value}</div>
                        </div>
                        ))}
                </div>



                <Row>
                    <Col className="ml-auto mr-auto pull-left">
                        <h2 className="title" style={{ marginTop: "30px" }}>How to Participate</h2>
                        <p>Taking advantage of Con10Craze Promotions is super easy! Follow these steps to make sure you don't miss out on any of them:</p>
                        <ul>
                            <li>
                                <strong>Keep an Eye on This Page:</strong> Bookmark this page and check regularly for updates on ongoing and upcoming promotions.
                            </li>
                            <li>
                                <strong>Follow Us on Social Media:</strong> We often share exclusive rewards and promotions on our social media platforms. Follow us on <a href="https://instagram.com/con10craze" target="_blank" rel="noopener noreferrer">Instagram</a>, <a href="https://facebook.com/con10craze.official" target="_blank" rel="noopener noreferrer">Facebook</a>, and <a href="https://twitter.com/con10craze" target="_blank" rel="noopener noreferrer">Twitter</a> for the latest updates.
                            </li>
                            <li>
                                Remember to review the terms and conditions for each promotion to ensure a smooth and enjoyable experience.
                            </li>
                        </ul>
                        <p>Thank you for being a part of the Con10Craze community! We can't wait to share more thrilling rewards with you. Happy gaming! 🚀🎮</p>
                    </Col>

                </Row>

                <Row style={{ marginTop: "25px" }}>
                    <Col className="ml-auto mr-auto pull-left">
                    <p style={{ fontStyle: "italic", fontWeight: "lighter", color: "#414040", fontSize: "14px" }}>* Referral bonuses are awarded for the first 10 referrals, but you’ll continue to earn coins for every referral, even beyond that limit!</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Promotions;
