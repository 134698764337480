export const localBaseUrl = "http://localhost:5000/";
export const prodBaseUrl = "https://cinemakrazy.us-east-1.elasticbeanstalk.com/";
export const baseUrl = prodBaseUrl;
export const idleTimeout = 1000 * 5 * 60;
export const loginWaitTime = 3000; // 3 seconds
export const localStripePublicKey = "pk_test_51MuaObSCDBcJ4QxWVGMQOeHz24o6aQ1stSRSDuipDz8hMHngQYWKd217aiNgpJyBfFi9VhwbEpL8fXxssiwRwMBB00hqrkSn6z";
export const productionStripePublicKey = "pk_live_51MuaObSCDBcJ4QxWfWKzxFGBrbvUELXWipEF0QX0mbBgnptxm1DqC3eFz6cqIKJPzmLKyhC43mPB1U1qlj7rt1nE00BJFcWF4s";
export const baseStripePublicKey = productionStripePublicKey;

export  const instagramGradientBoxColor = "#000000";
export const defaultProfileImage = "https://d78eqrbdvopvl.cloudfront.net/profile_img_template.png";
export const artistRoleName = "Creator_Request";

export const firebaseApiKey = "AIzaSyAQqnYtuvtYCzVqizOimPh98MO6hFYk2AM";
export const firebaseAuthDomain = "con10craze-25fa3.firebaseapp.com";
export const firebaseProjectId = "con10craze-25fa3";
export const firebaseStorageBucket = "con10craze-25fa3.appspot.com";
export const firebaseMessagingSenderId = "613424539698";
export const firebaseAppId = "1:613424539698:web:ba2d813eb59bb2c086b42b";
export const firebaseMeasurementId = "G-YGDC8RKLHN";

export const SignupBonus = 20;
export const ReferralBonus = 15;
export const StreakBonus = 5;

// Levels
export const CREATE_PRIVATE_CONTEST_LEVEL = 3;
export const GAME_THIRD_TEAM_CREATE_LEVEL = 25;
