import React, { useState } from "react";
import { Alert, Button, Col, Container, Row, Table } from "reactstrap";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { userService } from "services/user.service";
import { calculateTotalMoney } from "utils/helper";
import upi_code from 'assets/img/upi_code.png';
import { format } from "date-fns";
import MyModalDialog from "./MyModalDialog";
import { copyTextToClipboard } from "utils/helper";
import { artistRoleName } from "utils/constants";
import Streaks from "./Streaks";
import {pixelDepositConversionTracking} from "./../conversion-script";
import { useViewPort } from "utils/helper";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MyAccountBalance = ({streaksRef}) => {
    const navigate = useNavigate();
    const [showWithdrawDialog, setShowWithdrawDialog] = useState(false);
    const [showDepositDialog, setShowDepositDialog] = useState(false);
    const userData = userService.getLocalStorageData();
    const [data, setData] = useState([]);
    const [txdata, setTxData] = useState([]);
    const [alertWithdraw, setAlertWithdraw] = useState(false);
    const [kycComplete, setKycComplete] = useState(true);
    const [withdrawalAmount, setWithdrawalAmount] = useState(null);
    const [depositAmount, setDepositAmount] = useState(null);
    const [alertDeposit, setAlertDeposit] = useState(false);
    const [userUpiId, setUserUpiId] = useState("user_upi_id" in userData ? userData.user_upi_id : null);
    const [userMobileNumber, setUserMobileNumber] = useState("mobile_number" in userData ? userData.mobile_number : null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorType, setErrorType] = useState(0);
    const minWithdrawalAmount = ("role" in userData ? (userData["role"] === artistRoleName ? 10000 : 100 ) : 100);

    const { width } = useViewPort();
    const breakpoint = 1; // temporarily getting "Error" in mobile phones: 700;
    const [showSave, setShowSave] = useState(false);
    const [isUploading, setisUploading] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [profileFile, setProfileFile] = useState(null);

    const upi_money_deposit_video = "https://d78eqrbdvopvl.cloudfront.net/upi_money_deposit.mp4";
    const upi_money_deposit_video_width = width >= breakpoint ? "50%" : "60%";

    React.useEffect(() => {
        const fetchData = async () => {
            userService.getFantasyUserHistory()
                .then((res) => {
                    setData(res);
                })
                .catch((error) => {
                    return;
                })
            fetchMyTx();
        };
        fetchData();
    }, [])

    const fetchMyTx = () => {
        userService.getMyTx()
            .then((res) => {setTxData(res);})
            .catch((err) => {})
    }

    const printTime = (in_time) => {
        try {
            return (format(in_time, 'dd MMMM h:mm a'));
        } catch (e) {
            return in_time;
        }
    }

    const handleShowDepositDialog = (e) => {
        e.preventDefault();
        setShowDepositDialog(true);
    }

    const enableWithdrawDialog = (e) => {
        e.preventDefault();
        if (!('winnings' in userData)) {
            return;
        }
        if (userUpiId === null || userUpiId === undefined || userMobileNumber === null || userMobileNumber === undefined ) {
            setKycComplete(false);
            return;
        }
        if (userData['winnings'] < minWithdrawalAmount) {
            setAlertWithdraw(true);
            return;
        }
        setShowWithdrawDialog(true);
    }

    const handleWithdrawalAmountInput = (e) => {
        e.preventDefault();
        const val = parseInt(e.target.value);
        if (val === null || val === undefined || isNaN(val)) {
            setWithdrawalAmount('');
            return;
        }
        if (val > parseInt(userData["winnings"])) {
            return;
        }
        setWithdrawalAmount(val);
    }

    const handleDepositAmountInput = (e) => {
        e.preventDefault();
        const val = parseInt(e.target.value);
        if (val === null || val === undefined || isNaN(val)) {
            setDepositAmount('');
            return;
        }
        if ( val < 100 || val > 50000) {
//            return;
            setAlertDeposit(true);
        } else  {
            setAlertDeposit(false);
        }
        setDepositAmount(val);
    }

    const handleUserUpiIdChanges = (e) => {
        e.preventDefault();
        setUserUpiId(e.target.value);
    }

    const initiateWithdrawalTx = (e) => {
        e.preventDefault();
        setErrorType(0);
        let val = parseInt(withdrawalAmount);
        if (val === null || val === undefined || isNaN(val) || val < minWithdrawalAmount || val > parseInt(userData["winnings"])) {
            setErrorType(1);
            return;
        }
        if (userUpiId === null || userUpiId === undefined || userUpiId.trim().length === 0) {
            setErrorType(2);
            return;
        }

        userService.withdrawMoney(val, userUpiId)
        .then((res) => {
            setShowSuccess(true);
            setWithdrawalAmount(null);
            fetchMyTx();
            setTimeout(() => {
                setShowWithdrawDialog(false);
                setShowSuccess(false);
            }, 2000);
        })
        .catch((err) => {
            alert(err);
        })
    }

    const initiateDepositTx = (e) => {
        e.preventDefault();
        setErrorType(0);
        let val = parseInt(depositAmount);
        if (width >= breakpoint && (val === null || val === undefined || isNaN(val) || val < 100 || val > 50000 )) {
            setErrorType(1);
            setAlertDeposit(true);
            return;
        }
        if (width >= breakpoint && (userUpiId === null || userUpiId === undefined || userUpiId.trim().length === 0)) {
            setErrorType(2);
            return;
        }

        if (width < breakpoint && (profileFile === null || profileFile === undefined)) {
            return;
        }

        if ( width < breakpoint) {
            setisUploading(true);
        }
        const formData = new FormData();
        if (profileFile !== null && profileFile !== undefined) {
            formData.append('file', profileFile);
        }

        if (val !== null && val !== undefined && !isNaN(val)) {
            formData.append('deposit_amount', val);
        }
        if (userUpiId !== null && userUpiId !== undefined) {
            formData.append('user_upi_id', userUpiId);
        }

        userService.depositMoney(formData, (width >= breakpoint))
        .then((res) => {
            setisUploading(false);
            setProfileFile(null);
            pixelDepositConversionTracking(val)
            setShowSuccess(true);
            setDepositAmount(null);
            fetchMyTx();
            setTimeout(() => {
                setShowDepositDialog(false);
                setShowSuccess(false);
            }, 5000);
        })
        .catch((err) => {
            setisUploading(false);
            alert(err);
        })
    }

    const handleClipBoardClick = (e, data) => {
        e.preventDefault();
        copyTextToClipboard(data);
    }

    const photoUpload = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (file === null || file === undefined) {
            return;
        }
        const profileImageSize = 3000000; // 3 MB
        if (file.size > profileImageSize) {
            alert("Profile Image cannot be more than 3MB");
            return;
        }
        reader.onloadend = () => {
            setProfileFile(file);
            setShowSave(file !== null && file !== undefined);
            setImagePreviewUrl(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleCoinsClick = (e) => {
        e.preventDefault();
        navigate('/coins');
    }

    return (
        <div>
                <Row>
                    <Col className="ml-auto mr-auto">

                        <h3 style={{ marginBottom: "10px" }}>Total Balance</h3>
                        <CurrencyRupeeIcon />
                        <span style={{ fontSize: "larger", fontWeight: "bold" }}>{calculateTotalMoney(userData)}</span>

                        <h4 style={{ marginTop: "50px", marginBottom: "10px" }}>Money added (Unutilized)</h4>
                        <CurrencyRupeeIcon />
                        <span style={{ fontSize: "larger", fontWeight: "bold" }}>{'account_unutilized' in userData ? userData['account_unutilized'] : 0}</span>
                        <Button className='btn-round' color="info"
                                onClick={handleShowDepositDialog}
                                style={{marginLeft: "1.25rem"}}>Deposit Money</Button>
                        <Row className="center">
                            <p className="blink" style={{
                                color:"green",
                                fontWeight:"bold",
                                margin:"auto",
                                textAlign:"center"

                            }}>GET 100% CASHBACK UP TO ₹100 ON YOUR FIRST DEPOSIT!</p>
                        </Row>
                        <h4 style={{marginTop: "50px", marginBottom: "10px"}}>Bonus Money</h4>
                        <CurrencyRupeeIcon/>
                        <span style={{
                            fontSize: "larger",
                            fontWeight: "bold"
                        }}>{'bonus' in userData ? userData['bonus'] : 0}</span>

                        <h4 style={{ marginTop: "50px", marginBottom: "10px" }}>Winnings</h4>
                        <CurrencyRupeeIcon />
                        <span style={{ fontSize: "larger", fontWeight: "bold" }}>{'winnings' in userData ? userData['winnings'] : 0}</span>
                        <Button className='btn-round' color="info"
                            onClick={enableWithdrawDialog}
                            style={{marginLeft: "1.25rem"}}>Withdraw</Button>
                        <Alert color="danger" isOpen={!kycComplete}>
                            <Container>
                                Complete your KYC first by adding UPI & Mobile Number details in profile section
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setKycComplete(true)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>
                        <Alert color="danger" isOpen={alertWithdraw}>
                            <Container>
                                Minimum ₹{minWithdrawalAmount} is required to make a withdrawal request.
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setAlertWithdraw(false)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>

                        <h4 onClick={(e) => handleCoinsClick(e)} style={{ marginTop: "50px", marginBottom: "10px",
                            textDecoration: "underline"
                         }}>Coins</h4>
                        <span style={{ fontSize: "larger", fontWeight: "bold" }}>{'coins' in userData ? userData['coins'] : 0}</span>

                        <h3 style={{ marginTop: "50px", marginBottom: "0px" }}>My Games</h3>
                        <Table borderless style={{ display: "block", overflow: "auto" }}>
                            <thead style={{ backgroundColor: "#320544", color: "white" }}>
                                <tr>
                                    <th>
                                        Game
                                    </th>
                                    <th>
                                        Game Price
                                    </th>
                                    <th>
                                        Bonus Used
                                    </th>
                                    <th>
                                        Price Paid
                                    </th>
                                    <th>
                                        Score
                                    </th>
                                    <th>
                                        Game Earnings
                                    </th>
                                    <th>
                                        Withdrawable Winning Amount
                                    </th>
                                    <th>
                                        Payment Time
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((rowData, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">
                                                {rowData.name}
                                                <div className="team-name">
                                                    {"Team "}{(rowData.team_number)}
                                                </div>
                                            </th>
                                            <td>
                                            ₹{rowData.price}
                                            </td>
                                            <td>
                                            ₹{rowData.bonus_used}
                                            </td>
                                            <td>
                                            ₹{rowData.total_price}
                                            </td>
                                            <td>
                                                {parseFloat(rowData.score).toFixed(2)}
                                            </td>
                                            <td>
                                            ₹{rowData.earnings}
                                            </td>
                                            <td>
                                            ₹{rowData.withdrawable_winnings}
                                            </td>
                                            <td>
                                                {printTime(rowData.team_created_at)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>

                        <h3 style={{ marginTop: "50px", marginBottom: "0px" }}>My Transactions</h3>
                        <Table borderless style={{ display: "block", overflow: "auto" }}>
                            <thead style={{ backgroundColor: "#320544", color: "white" }}>
                                <tr>
                                    <th>
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        UPI Id
                                    </th>
                                    <th>
                                        Time
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {txdata.map((rowData, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">
                                                {index+1}
                                            </th>
                                            <th scope="row">
                                            ₹{rowData.amount}
                                            </th>
                                            <td>
                                                {rowData.tx_type}
                                            </td>
                                            <td>
                                                {rowData.user_upi_id}
                                            </td>
                                            <td>
                                                {printTime(rowData.created_at)}
                                            </td>
                                            <td>
                                                {rowData.tx_status}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>

                        <div ref={streaksRef}>
                            <Streaks/>
                        </div>

                    </Col>
                </Row>

            <MyModalDialog title="Withdraw your winnings" show={showWithdrawDialog} onClose={(e) => setShowWithdrawDialog(false)}>
                    <div className="carousel-item active withdrawal">
                        <div>
                            <Row className="text-center content-center" style={{ margin: "auto", marginTop: "10px" }}>
                                <Col className="ml-auto mr-auto text-center">

                                    <input type="number" placeholder="Withdrawal amount" value={withdrawalAmount} onChange={handleWithdrawalAmountInput}
                                        className={"ml-auto mr-auto text-center field center" + (errorType === 1 ? " error-input": "")} style={{ textAlign: "center" }}></input>
                                    <p>Min: <CurrencyRupeeIcon /><strong>100</strong> - Max: <CurrencyRupeeIcon /><strong>1 lakh</strong> per day</p>

                                    <h4 style={{ marginTop: "50px" }}>Winnings will be sent to</h4>
                                        <input type="text" placeholder="Your UPI Id" value={userUpiId} disabled onChange={handleUserUpiIdChanges}
                                             className={"ml-auto mr-auto text-center field center" + (errorType === 2 ? " error-input": "")} style={{ textAlign: "center" }}></input>

                                    <Col>
                                        <span style={{ textAlign: "center" , fontWeight: "lighter", fontSize: "smaller" }}>Con10Craze is not responsible if you provided a wrong UPI ID.</span>
                                    </Col>
                                    <Button className="btn-round" size="lg" style={{ marginTop: "30px" }}
                                        color="danger"
                                        type="button"
                                        onClick={initiateWithdrawalTx}
                                    >Withdraw Now</Button>
                                    {showSuccess && <Row>
                                        <span style={{ fontWeight: "bold" }}>Congratulations, you should receive a payment from con10craze@okhdfcbank shortly. In case of any issue, tou can contact us via email at contact-us@con10craze.com.</span>
                                    </Row>}
                                </Col>
                            </Row>
                        </div>
                    </div>

                </MyModalDialog>

                <MyModalDialog title="Deposit Money" show={showDepositDialog} onClose={(e) => setShowDepositDialog(false)}>
                    <div>
                        <Row className="text-center content-center">
                            <Col className="ml-auto mr-auto text-center">
                                <video autoPlay muted loop src={upi_money_deposit_video} width={upi_money_deposit_video_width} />
                            </Col>
                        </Row>
                        <Row style={{ marginLeft: "5%", marginTop: "1%" }}>
                            <Col className="ml-auto mr-auto text-center">
                                <span style={{ color: "grey", fontSize: "bold" }}>
                                    con10craze@okhdfcbank
                                </span>
                                <ContentCopyIcon className="copy-icon" onClick={(e) => {
                                    handleClipBoardClick(e, "con10craze@okhdfcbank")
                                }} style={{ cursor: "pointer", width: "auto", marginLeft: "10px", fontSize: "25px", }} />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                            <Col className="ml-auto mr-auto">
                                <span style={{ fontWeight: "normal", }}>1. Copy the Con10Craze UPI ID.</span>
                                <br />
                                <span style={{ fontWeight: "normal", }}>2. Make the payment from any UPI app.</span>
                                <br />
                                {width >= breakpoint &&
                                <span
                                    style={{ fontWeight: "normal", }}>3. Enter your UPI Id from which you are making the payment and the exact amount in INR.</span>
                                }
                                {width < breakpoint &&
                                <span
                                    style={{ fontWeight: "normal", }}>3. Upload the payment confirmation page screenshot.</span>
                                }
                                <br />
                                <span style={{ fontWeight: "normal", }}>4. Click Submit after payment is completed.</span>
                            </Col>
                        </Row>
                        {width < breakpoint &&<Row className="text-center content-center">
                            <div style={{width: "100%", maxHeight: "150px"}}>
                                <label className="custom-file-upload fas">
                                    {imagePreviewUrl !== null && imagePreviewUrl != undefined &&
                                    <div className="img-wrap img-upload">
                                        <img src={imagePreviewUrl} width="150px" style={{
                                            border: "2px solid grey", maxHeight: "150px"
                                        }}/>
                                    </div>}
                                    <input id="photo-upload" type="file" accept="image/jpeg, image/png"
                                        onChange={photoUpload}/>
                                        <span style={{ textDecoration: "underline" }}>Upload Screenshot!</span>
                                </label>
                            </div>
                        </Row>}

                        {isUploading && (
                            <div style={{
                                position: "fixed",
                                top: "0",
                                left: "0",
                                width: "100vw",
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Row style={{
                                    backgroundColor: "black",
                                    padding: "20px",
                                    color: "white",
                                    borderRadius: "20px",
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                    <CircularProgress thickness={6} size={50}/>
                                    <h4 style={{display: "contents"}}>Deposit in progress...</h4>
                                </Row>
                            </div>
                        )}


                        {width >= breakpoint && <Row className="text-center content-center" style={{ margin: "auto", marginTop: "10px" }}>
                            <input type="text" value={userUpiId} placeholder="Your UPI Id"
                                onChange={handleUserUpiIdChanges}
                                className="ml-auto mr-auto text-center field center" style={{ textAlign: "center" }}></input>

                            <input type="number" placeholder="Deposit amount" value={depositAmount} onChange={handleDepositAmountInput}
                                className={"ml-auto mr-auto text-center field center" + (errorType === 1 ? " error-input": "")} style={{ textAlign: "center" }}></input>
                        </Row>}
                        <Alert color="danger" isOpen={alertDeposit}>
                            <Container>
                                Please deposit between ₹100 and ₹50,000 only.
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setAlertDeposit(false)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>

                        <Row className="text-center content-center" style={{ margin: "auto", marginTop: "20px" }}>
                            <Button className="ml-auto mr-auto text-center btn-round" size="lg"
                                disabled={imagePreviewUrl === null && (userUpiId === null || userUpiId === undefined || userUpiId.trim().length < 1)}
                                color="danger"
                                type="button"
                                onClick={initiateDepositTx}
                            >SUBMIT</Button>
                        </Row>

                        <Col className="ml-auto mr-auto text-center">
                                {showSuccess && <Row>
                                    <span style={{ fontWeight: "bold" }}>Congratulations, your deposit request has been received and your money would be reflected in your Con10Craze account shortly. In case of any issue, you can contact us via email at contact-us@con10craze.com.</span>
                                </Row>}
                        </Col>

                        <Row>
                            <span style={{ fontWeight: "lighter", fontSize: "smaller" }}>NOTE: Payment should only be done from the UPI provided above and of the same amount. Platform will not be responsible for any issues with a different UPI id or amount.</span>
                        </Row>
                    </div>
                </MyModalDialog>

        </div>
    )
}

export default MyAccountBalance;