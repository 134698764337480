import React, { useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, Container, FormGroup, Input, Label, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane, Table, Tooltip } from 'reactstrap';
import Confetti from "react-confetti";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import SEO from '../utils/seo';
import { userService } from 'services/user.service';
import InfoIcon from '@mui/icons-material/Info';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import FantasyGameHader from 'components/Headers/FantasyGameHeader';
import DarkFooter from 'components/Footers/DarkFooter';
import MyModalDialog from './index-sections/MyModalDialog';
import { format } from 'date-fns';
import upi_code from 'assets/img/upi_code.png';

import {copyTextToClipboard} from "../utils/helper";
import {gameJoinedConversionTag} from "./conversion-script";
import Playground from './Playground';
import Scorecard from './Scorecard';
import {pixelPurchaseConversionTracking} from "./conversion-script";
import { GAME_THIRD_TEAM_CREATE_LEVEL } from 'utils/constants';

const FantasyGamePage = () => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    const navigate = useNavigate();
    const location = useLocation();
    const [shareGame] = React.useState(location.state ? "shareGame" in location.state ? location.state["shareGame"] : null : null);

    const [showPlayersLeaderboard, setShowPlayersLeaderboard] = useState(false);
    const [isUpcoming, setIsUpcoming] = useState(true);
    const [userUpiId, setUserUpiId] = useState("");

    // team specific data
    const [teamsAllowed, setTeamsAllowed] = useState(3);
    const [selectArtistCount, setSelectArtistCount] = useState({}); // 0
    const [set_size, setSetSize] = useState({}); // 0
    const [selectedArtists, setSelectedArtists] = useState({}); // []
    const [artists, setArtists] = useState({}); // []
    const [paymentUnderProcessingNotify, setPaymentUnderProcessingNotify] = React.useState({});
    const [paymentFailedNotify, setPaymentFailedNotify] = React.useState({});
    const [teamAllowedIndex, setTeamsAllowedIndex] = React.useState([true, false, false]);

    const { game_external_id } = useParams();
    const [game, setGame] = useState({ "details": {} });
    const [artistsRanking, setArtistsRanking] = useState([]);
    const [topParticipants, setTopParticipants] = useState([]);
    const [winningDistribution, setWinningDistribution] = useState([]);
    const [artistWinningDistribution, setArtistWinningDistribution] = useState([]);

    const [errorMsg, setErrorMsg] = useState(null);
    const [errorHref, setErrorHref] = useState(null);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [showPodium, setShowPodium] = useState(false);
    const [booster, setBooster] = useState(1);
    const [maxBooster, setMaxBooster] = useState(1);
    const [showConfetti, setShowConfetti] = useState(false);
    const userData = userService.getLocalStorageData();
    const userLevel = ('level' in userData && userData['level'] > 0) ? userData['level'] : 1;

    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');

    const [iconPills, setIconPills] = React.useState("1");
    const [teamPills, setTeamPills] = React.useState(1);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpenPrize, setTooltipOpenPrize] = useState(false);
    const [artistTooltipOpen, setArtistTooltipOpen] = useState(false);
    const [artisttooltipOpenPrize, setArtistTooltipOpenPrize] = useState(false);

    const [disablePayButton, setDisablePayButton] = useState(true);
    const [payDialogShown, setPayDialogShown] = useState(1);

    // notifications
    const [loginNotify, setLoginNotify] = React.useState(false);
    const [createTeamNotify, setCreateTeamNotify] = React.useState(false);
    const [reviewTeamNotify, setReviewTeamNotify] = React.useState(false);
    const [winningDetails, setWinningDetails] = React.useState(false);
    const [artistWinningDetails, setArtistWinningDetails] = React.useState(false);
    const [gameNotify, setGameNotify] = React.useState(false);

    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        if (game_external_id === undefined || game_external_id === null || game_external_id.trim().length === 0) {
            navigate('/');
        }

        if (userService.isLoggedIn()) {
            userService.getUserWalletDetails();
        }

        fetchGameDetails();
    }, []);

    const initTeams = (res) => {
        let allowedTeams = 1;

        if ("teams_allowed" in res["details"] && res["details"]["teams_allowed"] > 0) {
            allowedTeams = res["details"]["teams_allowed"];
            setTeamsAllowed(allowedTeams);
        }
    }

    const initArtists = (res) => {
        let allowedTeams = 1;
        let allArtists = {};
        let anyTeamNotSelected = true;

        if ("teams_allowed" in res["details"] && res["details"]["teams_allowed"] > 0) {
            allowedTeams = res["details"]["teams_allowed"];
        }

        for (var teamNum = 1; teamNum <= allowedTeams; ++teamNum) {
            allArtists[teamNum] = [];
            for (var i = 0; i < res["artists"].length; ++i) {
                allArtists[teamNum].push(res["artists"][i]);
                allArtists[teamNum][i].selected = false;
            }
        }

        let newSelectedArtists = {};
        let newSelectedArtistsCount = {};
        let teamsSetSize = {};
        for (var j = 1;j <= allowedTeams; ++j) {
            newSelectedArtists[j] = [];
            for (var i = 0; i < res["details"]["team_size"]; ++i) {
                newSelectedArtists[j].push({ "default": true });
            }
            teamsSetSize[j] = newSelectedArtists[j].length;
            newSelectedArtistsCount[j] = 0;
        }

        if ("user_team" in res && Object.keys(res["user_team"]).length > 0) {
            anyTeamNotSelected = false;
            // if there is at least one team that uer has not yet created
            if (Object.keys(res["user_team"]).length < allowedTeams) {
                anyTeamNotSelected = true;
            }
            // traverse over each team -> res["user_team"]: {1: [<list of artists>], 2: [...], 3: [...]}
            Object.keys(res["user_team"]).map((key, index) => {
                const teamNumber = key;
                const teamData = res["user_team"][key];
                // if user has not selected any artists in a team
                if (!teamData || teamData.length === 0) {
                    anyTeamNotSelected = true;
                }
                newSelectedArtists[teamNumber] = [];
                for (var i = 0; i < teamData.length; ++i) {
                    newSelectedArtists[teamNumber].push({
                        "default": false, "uuid": teamData[i].uuid,
                        "profile_img": teamData[i].profile_img, "name": teamData[i].name
                    });

                    allArtists[teamNumber] = allArtists[teamNumber].map((artist) => {
                        if (artist["uuid"] === teamData[i].uuid) {
                            const updatedArtist = {
                                ...artist,
                                selected: true,
                            };
                            return updatedArtist;
                        }
                        return artist;
                    });
                }
                teamsSetSize[teamNumber] = newSelectedArtists[teamNumber].length;
                newSelectedArtistsCount[teamNumber] = newSelectedArtists[teamNumber].length;
            })
            // showConfettiFn();
        } else {
            for (var j = 1;j <= allowedTeams; ++j) {
                newSelectedArtists[j] = [];
                for (var i = 0; i < res["details"]["team_size"]; ++i) {
                    newSelectedArtists[j].push({ "default": true });
                }
                teamsSetSize[j] = newSelectedArtists[j].length;
                newSelectedArtistsCount[j] = 0;
            }
        }

        setSetSize(teamsSetSize);
        setSelectedArtists(newSelectedArtists);
        setArtists(allArtists);
        setSelectArtistCount(newSelectedArtistsCount);

        return anyTeamNotSelected;
    }

    const initPayment = (res) => {
        let allowedTeams = 1;
        if ("teams_allowed" in res["details"] && res["details"]["teams_allowed"] > 0) {
            allowedTeams = res["details"]["teams_allowed"];
        }
        let paymentBeingProcessedTemp = {};
        let paymentFailedNotifyTemp = {};
        for (var i = 1;i <= allowedTeams; ++i) {
            if (i in res["user_details"] && "status" in res["user_details"][i]) {
                if (res["user_details"][i]["status"] === "CREATED") {
                    paymentBeingProcessedTemp[i] = true;
                } else if (res["user_details"][i]["status"] === "FAILED") {
                    paymentFailedNotifyTemp[i] = true;
                }
            }    
        }
        let localTeamsAllowedIndex = teamAllowedIndex
        for (var i = 2;i <= allowedTeams; ++i) {
            if ((i-1) in res["user_details"]
                && "status" in res["user_details"][i-1]
                && res["user_details"][i-1]["status"] === "PROCESSED") {
                teamAllowedIndex[i-1] = true;
            } else {
                teamAllowedIndex[i-1] = false;
            }

        }
        setTeamsAllowedIndex(localTeamsAllowedIndex)
        setPaymentUnderProcessingNotify(paymentBeingProcessedTemp);
        setPaymentFailedNotify(paymentFailedNotifyTemp);
    }

    const fetchGameDetails = () => {
        userService.getFantasyGameDetails(game_external_id)
            .then((res) => {
                let now_time = new Date();
                let start_time = new Date(res["details"]["start_time"]);
                const isUpcoming = start_time > now_time;
                setIsUpcoming(isUpcoming);
                setGame(res);
                setTitle(res['details']["name"] + " | Con10Craze Fantasy Sports");
                setUrl("https://con10craze.com/game/" + game_external_id);

                if ("max_booster" in res['details'] && res["details"]["max_booster"] > 1) {
                    setMaxBooster(res['details']["max_booster"]);
                }
        
                initTeams(res);
                let anyTeamNotSelected = initArtists(res);
                initPayment(res);

                if (!userService.isLoggedIn()) {
                    setCreateTeamNotify(true);
                    setLoginNotify(true);
                } else {
                    let now_time = new Date();
                    let particpation_time = new Date(game["details"]["participation_time"]);
                    if (particpation_time > now_time) {
                        if (anyTeamNotSelected) {
                            setCreateTeamNotify(true);
                        } else {
                            setGameNotify(true);
                            setCreateTeamNotify(false);
                        }
                    }
                }
                setTopParticipants(res["leaderboard"]);
                if ( res["status"] === "completed") {
                        setShowPodium(true)
                }
            })
            .catch((err) => {
            })

        if (userService.isLoggedIn()) {
            userService.getPlayersLeaderboard(game_external_id)
                .then((res) => {
                    if ( res["artist_view"]) {
                        setArtistsRanking(res["ranked_artists"]);
                        setShowPlayersLeaderboard(true);
                    }
                })
                .catch((err) => {
                })
        }

    }

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const toggleTooltipPrize = () => setTooltipOpenPrize(!tooltipOpenPrize);
    const toggleArtistsTooltip = () => setArtistTooltipOpen(!artistTooltipOpen);
    const toggleArtistsTooltipPrize = () => setArtistTooltipOpenPrize(!artisttooltipOpenPrize);

    const handleUserUpiIdChanges = (e) => {
        e.preventDefault();
        setUserUpiId(e.target.value);
    }

    const showConfettiFn = () => {
        setShowConfetti(true);
        fetchGameDetails();
        setTimeout(function () {
            setShowConfetti(false);
        }, 7500);
    }

    const handleTabChange = (e, tabIndex) => {
        e.preventDefault();
        setIconPills(tabIndex);
    };

    const handleTeamTabChange = (e, tabIndex) => {
        e.preventDefault();
        setTeamPills(tabIndex);
    };

    const showWinners = () => {
        if (!topParticipants || (topParticipants && topParticipants.length < 3)) {
            return (
                <Row></Row>
            )
        }
        return (

            <Row className="text-center justify-content-center"  style={{ width:"100%",padding:"20px", margin:"auto"}}>
                    <div className="podium">
                        <div className="podium__item">
                            <p className="podium__name">{topParticipants[1].first_name}</p>
                            <div className="podium__rank second">2</div>
                        </div>
                        <div className="podium__item">
                            <p className="podium__name">{topParticipants[0].first_name}</p>
                            <div className="podium__rank first">1</div>
                        </div>
                        <div className="podium__item">
                            <p className="podium__name">{topParticipants[2].first_name}</p>
                            <div className="podium__rank third">3</div>
                        </div>
                    </div>
            </Row>
        )
    }

    const notifyToCreateTeam = () => {
        const teamNumber = teamPills;
        return (
            <div className="game-popup">

                <Alert color="success" isOpen={createTeamNotify} >
                    <Container>
                        <div className="alert-icon">
                            <i className="now-ui-icons ui-1_simple-add"></i>
                        </div>
                        Select {set_size[teamNumber]} Creators to create your team<br></br><strong>({selectArtistCount[teamNumber]} out of {set_size[teamNumber]} selected)</strong>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setCreateTeamNotify(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>
            </div>
        )
    }

    const clearTeamSelection = (teamNum) => {
        let newSelectedArtists = {...selectedArtists};
        let newArtists = {...artists};
        for (var i = 0; i < selectedArtists[teamNum].length; ++i) {
            newSelectedArtists[teamNum][i].default = true;
            newSelectedArtists[teamNum][i].profile_img = "";
            newSelectedArtists[teamNum][i].uuid = null;
            newSelectedArtists[teamNum][i].name = null;
        }
        for (var i = 0; i < artists[teamNum].length; ++i) {
            newArtists[teamNum][i].selected = false;
        }
        let newSelectArtistCount = {};
        for (var i = 1;i <= teamsAllowed; ++i) {
            newSelectArtistCount[i] = 0;
        }
        setSelectArtistCount(newSelectArtistCount);

        setArtists(newArtists);
        setSelectedArtists(newSelectedArtists);
        setReviewTeamNotify(false);
        setWinningDetails(false);
        setArtistWinningDetails(false);
        setErrorMsg(null);
        togglePayButton()
    }

    const handleClipBoardClick = (e, data) => {
        e.preventDefault();
        copyTextToClipboard(data);
    }

    const makeRazorpayPayment = (e, totalPrice, accountUnutilizedTaken, bonusTaken, teamNumber) => {
        e.preventDefault();
        setCreateTeamNotify(false);
        const selectedArtistsUuids = selectedArtists[teamNumber].map((selectedArtist) => {
            return selectedArtist["uuid"];
        });

        userService.makeFantasyGamePayment(game_external_id, selectedArtistsUuids, booster, totalPrice, 
            accountUnutilizedTaken, bonusTaken, userUpiId, teamNumber)
        .then((res) => {
            window.dispatchEvent( new Event('storage') );
            setReviewTeamNotify(false);
            setPaymentUnderProcessingNotify(prevDict => ({ ...prevDict, [teamNumber]: true }));

            setGameNotify(true);
            showConfettiFn();
            gameJoinedConversionTag(totalPrice);
            pixelPurchaseConversionTracking(totalPrice)
        })
        .catch((err) => {
        })
    }

    const winningDetailsView = () => {
        return <MyModalDialog title="" show={winningDetails} onClose={() => setWinningDetails(false)} >
            <Row>
                <Col className="ml-auto mr-auto text-center">
                    <h2>Winnings Distribution</h2>
                    {/*<h3>{game["details"]["name"]}</h3>*/}
                </Col>
            </Row>
            <Row style={{justifyContent: "center"}}>
                <div>
                    <Col className='ml-auto mr-auto text-center'>
{/*                        <p>Prize Pool #Spots: </p>*/}

                        <Table id="winningDetails" className="winning-details" width="100%">
                            <thead>
                            <tr>
                                <th>
                                    Rank
                                </th>
                                <th>
                                   Winning Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {winningDistribution.map((rowData, index) => {
                                return (
                                    <tr>
                                        <th scope="row">
                                            {index + 1}
                                        </th>
                                        <td>
                                        ₹{rowData.toLocaleString()}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </div>
            </Row>

        </MyModalDialog>


    }

    const artistWinningDetailsView = () => {
        return <MyModalDialog title="" show={artistWinningDetails} onClose={() => setArtistWinningDetails(false)} >
            <Row>
                <Col className="ml-auto mr-auto text-center">
                    <h2>Creators Winnings Distribution</h2>
                </Col>
            </Row>
            <Row style={{justifyContent: "center"}}>
                <div>
                    <Col className='ml-auto mr-auto text-center'>
                        <Table id="artistWinningDetails" className="winning-details" width="100%">
                            <thead>
                            <tr>
                                <th>
                                    Rank
                                </th>
                                <th>
                                   Winning Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {artistWinningDistribution.map((rowData, index) => {
                                return (
                                    <tr>
                                        <th scope="row">
                                            {index + 1}
                                        </th>
                                        <td>
                                            {rowData}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </div>
            </Row>
        </MyModalDialog>
    }

    const togglePayButton = () => {
        setDisablePayButton(!disablePayButton);
    }

    const reviewTeam = () => {
        const teamNumber = teamPills;
        let askForPayment = game["details"]["type"] !== "practice" && game["details"]["price"] > 0;
        let totalPrice = game["details"]["price"] * booster;
        const accountUnutilized = 'account_unutilized' in userData ? userData['account_unutilized'] : 0;
        const totalBonus = 'bonus' in userData ? userData['bonus'] : 0;
        // Allow maximum bonus of Rs 45 to be used in a public game or 0 otherwise
        const maxBonusAllowed = (game["details"]["type"] === "main") ? 45 : 0;

        const bonus = totalBonus < maxBonusAllowed ? totalBonus : maxBonusAllowed;

        const showBonusTaken = bonus > 0 && totalPrice > 0;
        let bonusTaken = totalPrice >= bonus ? bonus: totalPrice;
        if (showBonusTaken === true) {
            totalPrice = totalPrice - bonusTaken;
        } else {
            bonusTaken = 0;
        }

        const showAccountUnutilizedTaken = accountUnutilized > 0 && totalPrice > 0;
        let accountUnutilizedTaken = totalPrice >= accountUnutilized ? accountUnutilized: totalPrice;
        if (showAccountUnutilizedTaken === true) {
            totalPrice = totalPrice - accountUnutilizedTaken;
        } else {
            accountUnutilizedTaken = 0;
        }

        askForPayment = askForPayment && totalPrice > 0;

        return (
            <MyModalDialog title="" show={reviewTeamNotify} onClose={(e) => clearTeamSelection(teamNumber)}>

                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" >
                    <div >

                        <div className={"carousel-item" + (payDialogShown === 1 ? " active": "")}>
                            <Row >
                                <Col className="ml-auto mr-auto text-center">
                                    <h2>Review Your Team</h2>
                                    <h3 style={{ marginBottom: "0px" }}>{game["details"]["name"]}</h3>
                                    <h5 style={{ marginBottom: "0px" }}>Team {teamNumber}</h5>
                                </Col>
                            </Row>

                            <Row>
                                {teamNumber in selectedArtists && selectedArtists[teamNumber].map((artist, index) => {
                                    return (
                                        <Col className="ml-auto mr-auto text-center" xs="6" md="4">
                                            <Card className="card-login card-plain" style={{ marginTop: "30px", marginBottom: "0px" }}>
                                                <CardHeader className="text-center">
                                                    <div className="logo-container">
                                                        <img
                                                            alt="Con10Craze Fantasy Creators, Artists, Influencers, Celebrities"
                                                            src={artist["profile_img"]}
                                                            style={{ borderRadius: "10px" }}
                                                            height="100"
                                                        ></img>
                                                        <h4 className="artist-name"
                                                            style={{
                                                                display: "flex",
                                                                fontSize: "initial",
                                                                justifyContent: "center",
                                                                textAlign: "center",
                                                                background: `linear-gradient(45deg, #405de6, #5851db, #833AB4, #c13584, #E1306c, #fd1d1d)`,
                                                                backgroundClip: "text",
                                                                color: "transparent"
                                                            }}>
                                                            {artist.name}</h4>
                                                    </div>
                                                </CardHeader>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>

                            {askForPayment &&
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px", color: "#333", fontWeight: "bold" }}>Game Price:</h5>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px", color: "#555", fontWeight: "bold" }}>₹{game["details"]["price"]}</h5>
                                    </Col>

                                    {showBonusTaken && (
                                        <>
                                            <Col className="ml-auto mr-auto text-center" xs="6">
                                                <h5 className='pull-right' style={{ color: "green", fontWeight: "bold", marginTop: "5px" }}>Bonus:</h5>
                                            </Col>
                                            <Col className="ml-auto mr-auto text-center" xs="6">
                                                <h5 className='pull-left' style={{ color: "green", fontWeight: "bold", marginTop: "5px" }}>- ₹{bonusTaken}</h5>
                                            </Col>
                                        </>
                                    )}

                                    {showAccountUnutilizedTaken && (
                                        <>
                                            <Col className="ml-auto mr-auto text-center" xs="6">
                                                <h5 className='pull-right' style={{ marginTop: "5px", color: "#333", fontWeight: "bold" }}>Amount already deposited:</h5>
                                            </Col>
                                            <Col className="ml-auto mr-auto text-center" xs="6">
                                                <h5 className='pull-left' style={{ marginTop: "5px", color: "#555", fontWeight: "bold" }}>- ₹{accountUnutilizedTaken}</h5>
                                            </Col>
                                        </>
                                    )}

                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px", color: "#333", fontWeight: "bold" }}>Total Price:</h5>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px", color: "#555", fontWeight: "bold" }}>₹{totalPrice}</h5>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center center" xs="12">
                                        <input type="checkbox" aria-label="Checkbox for following text input" onChange={togglePayButton}></input>
                                        <span id="age-limit-check" aria-hidden="true">&nbsp;&nbsp;I certify that I am at least 18 years old</span>
                                    </Col>

                                    <Button className="ml-auto mr-auto text-center btn-round" size="lg" color="danger" type="button"
                                        disabled={disablePayButton}
                                        onClick={(e) => setPayDialogShown(2)}
                                        >Pay Now</Button>

                                    {game["details"]["type"] === "private" && <Row className="ml-auto mr-auto text-center"><p style={{ fontStyle: "italic", fontSize: "small", display: "flex", justifyContent: "center", textAlign: "center" }}>* Bonus money cannot be used in Private Contests</p></Row>}
                                </Row>
                            }

                            {!askForPayment &&
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px" }}>Game Price:</h5>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px" }}>₹{game["details"]["price"]}</h5>
                                    </Col>

                                    {showBonusTaken && <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px" }}>Bonus:</h5>
                                    </Col>}
                                    {showBonusTaken && <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px" }}>- ₹{bonusTaken}</h5>
                                    </Col>}

                                    {showAccountUnutilizedTaken && <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-right' style={{ marginTop: "5px" }}>Amount already deposited:</h5>
                                    </Col>}
                                    {showAccountUnutilizedTaken && <Col className="ml-auto mr-auto text-center" xs="6">
                                        <h5 className='pull-left' style={{ marginTop: "5px" }}>- ₹{accountUnutilizedTaken}</h5>
                                    </Col>}

                                    <Button className="ml-auto mr-auto text-center btn-round" size="lg" color="danger" type="button"
                                        onClick={(e) => makeRazorpayPayment(e, totalPrice, accountUnutilizedTaken, bonusTaken, teamNumber)}>Submit</Button>
                                </Row>
                            }
                        </div>

                        <div className={"carousel-item" + (payDialogShown === 2 ? " active": "")}>
                            <div>
                                <Row>
                                    <Col className="ml-auto mr-auto text-center">
                                        <h3 className="title">Complete your Payment</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="ml-auto mr-auto ">
                                        <span style={{ fontWeight: "normal", }}>1. Make a payment of <strong>₹{totalPrice}</strong> from any UPI app to the Con10Craze UPI Id shared here.</span>
                                        <br />
                                        <br />
                                        <span
                                            style={{ fontWeight: "normal", }}>2. Enter your UPI Id from which you made the payment and click submit.</span>
                                        <br />
                                        <br />
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center center">
                                        <Row className="text-center content-center" style={{ marginLeft: "10%" }}>
                                            <img
                                                alt="Con10Craze UPI code"
                                                src={upi_code}
                                                height="200"
                                            ></img>
                                        </Row>
                                        <Row style={{  marginTop: "1%", justifyContent:"center"}}>
                                            <span style={{ color: "#B00610", fontWeight:"bolder" }}>
                                                con10craze@okhdfcbank
                                            </span>
                                            <ContentCopyIcon className="copy-icon center" onClick={(e) => {
                                                handleClipBoardClick(e, "con10craze@okhdfcbank")
                                            }} style={{ cursor: "pointer", width: "auto", fontSize: "25px", }} />
                                        </Row>

                                    </Col>

                                </Row>
                                <Row className="text-center content-center" style={{ margin: "auto", width: "50%", marginTop: "10px" }}>
                                    <input type="text" value={userUpiId} placeholder="Your UPI Id"
                                        onChange={handleUserUpiIdChanges}
                                        className="ml-auto mr-auto text-center field center" style={{ textAlign: "center" }}></input>
                                </Row>
                                <Row>
                                    <Button className="ml-auto mr-auto text-center btn-round" size="lg"
                                        disabled={userUpiId === null || userUpiId === undefined || userUpiId.trim().length < 1}
                                        color="info"
                                        type="button"
                                        onClick={(e) => makeRazorpayPayment(e, totalPrice, accountUnutilizedTaken, bonusTaken, teamNumber)}
                                    >SUBMIT</Button>
                                </Row>
                                <Row>
                                    <span style={{ fontWeight: "lighter", fontSize: "smaller" }}>NOTE: Payment should only be done from the UPI provided above and of ₹{totalPrice}. Platform will not be responsible for any issues with a different UPI id or amount.</span>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">*/}
                {/*    <span className="carousel-control-prev-icon" aria-hidden="true"></span>*/}
                {/*    <span className="sr-only">Previous</span>*/}
                {/*</a>*/}
                {/*<a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">*/}
                {/*    <span className="carousel-control-next-icon" aria-hidden="true"></span>*/}
                {/*    <span className="sr-only">Next</span>*/}
                {/*</a>*/}

            </MyModalDialog>
        )
    }

    const notifyToWaitForGame = () => {
        return (
            <div className="game-popup">

                <Alert color="success" isOpen={gameNotify} >
                    <Container>
                        <div className="alert-icon">
                            <i className="now-ui-icons ui-2_like"></i>
                        </div>
                        Congratulations on creating your team! Now, sit back and wait for the game to launch.
                        <button
                            type="button"
                            className="close"
                            onClick={() => setGameNotify(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>
            </div>
        )
    }

    const notifyForLeaderboard = () => { }

    const congratsOnTeam = (teamNumber) => {
        if (!userService.isLoggedIn()) {
            setErrorMsg("Log in to get started...");
            setErrorHref("/login");
            setShowErrorDialog(true);
            return;
        }
        let paymentStatus = ("user_details" in game && teamNumber in game["user_details"]) ? game["user_details"][teamNumber]["payment_status"] : null;
        if (paymentStatus === "PROCESSED" || paymentStatus === "CREATED" || paymentStatus === "PAID") {
            setErrorMsg("You cannot modify your team after making payment");
            setShowErrorDialog(true);
            return;
        }
        let now_time = new Date();
        let particpation_time = new Date(game["details"]["participation_time"]);
        if (particpation_time < now_time) {
            setErrorMsg("The deadline to participate in this game has ended. Please check other upcoming games.");
            setShowErrorDialog(true);
            return;
        }

        setReviewTeamNotify(true);
    }

    const notifyForPaymentUnderProcessing = () => {
        const teamNumber = teamPills;
        return (
            <div className="game-popup">

                <Alert color="warning" isOpen={teamNumber in paymentUnderProcessingNotify && paymentUnderProcessingNotify[teamNumber] === true} >
                    <Container>
                        <div className="alert-icon">
                            <i className="now-ui-icons sport_user-run"></i>
                        </div>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setPaymentUnderProcessingNotify(prevDict => ({ ...prevDict, [teamNumber]: false }))}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                        Your payment is currently being processed and will be updated shortly.<br></br>
                        <p style={{ fontStyle: "italic", fontSize: "small", fontWeight: "400" }}>Please ensure that the payment was made from the UPI ID provided. In case of any issue, please contact us via <a href="mailto:contact-us@con10craze.com" style={{ color: "blue" }}>Email</a> or <a href="https://api.whatsapp.com/send?phone=919380740935" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Whatsapp</a></p>
                    </Container>
                </Alert>
            </div>
        )
    }

    const notifyForPaymentFailed = () => {
        const teamNumber = teamPills;
        return (
            <div className="game-popup">

                <Alert color="danger" isOpen={teamNumber in paymentFailedNotify && paymentFailedNotify[teamNumber] === true} >
                    <Container>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setPaymentFailedNotify(prevDict => ({ ...prevDict, [teamNumber]: false}))}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                        Your previous payment failed. Please create your team and make the payment again.<br></br>
                        <p style={{ fontStyle: "italic", fontSize: "small", fontWeight: "400" }}>If you believe this is a mistake, please contact us via <a href="mailto:contact-us@con10craze.com" style={{ color: "blue" }}>Email</a> or <a href="https://api.whatsapp.com/send?phone=919380740935" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Whatsapp</a></p>
                    </Container>
                </Alert>
            </div>
        )
    }

    const popupNotifications = () => {
        return (
            <>
                {notifyToCreateTeam()}
                {reviewTeam()}
                {notifyToWaitForGame()}
                {notifyForLeaderboard()}
                {winningDetailsView()}
                {artistWinningDetailsView()}
                {notifyForPaymentUnderProcessing()}
                {notifyForPaymentFailed()}
            </>
        )
    }

    const addToTeam = (artistIndex, teamNumber) => {
        if (selectArtistCount[teamNumber] === set_size[teamNumber]) {
            setErrorMsg("You have already selected your team. Please remove a creator from your team first.");
            setShowErrorDialog(true);
            return;
        }
        let newSelectedArtists = {...selectedArtists};
        for (let i = 0; i < newSelectedArtists[teamNumber].length; ++i) {
            if (newSelectedArtists[teamNumber][i].default === true) {

                newSelectedArtists[teamNumber][i].default = false;
                newSelectedArtists[teamNumber][i].uuid = artists[teamNumber][artistIndex].uuid;
                newSelectedArtists[teamNumber][i].profile_img = artists[teamNumber][artistIndex].profile_img;
                newSelectedArtists[teamNumber][i].name = artists[teamNumber][artistIndex].name;

                let newArtists = {...artists};
                newArtists[teamNumber][artistIndex].selected = !newArtists[teamNumber][artistIndex].selected
                setArtists(newArtists);
                setSelectedArtists(newSelectedArtists);
                let selectArtistCountTemp = selectArtistCount[teamNumber] + 1;
                setSelectArtistCount(prevDict => ({... prevDict, [teamNumber]: prevDict[teamNumber] + 1}));
                if (selectArtistCountTemp === set_size[teamNumber]) {
                    congratsOnTeam(teamNumber);
                }
                break;
            }
        }
    }

    const dropFromTeam = (artistIndex, teamNumber) => {
        let newSelectedArtists = {...selectedArtists};
        for (var i = 0; i < newSelectedArtists[teamNumber].length; ++i) {
            if (newSelectedArtists[teamNumber][i].uuid === artists[teamNumber][artistIndex].uuid && newSelectedArtists[teamNumber][i].default === false) {

                newSelectedArtists[teamNumber][i].default = true;
                newSelectedArtists[teamNumber][i].profile_img = "";
                newSelectedArtists[teamNumber][i].uuid = null;
                newSelectedArtists[teamNumber][i].name = null;

                let newArtists = {...artists};
                newArtists[teamNumber][artistIndex].selected = !newArtists[teamNumber][artistIndex].selected
                setArtists(newArtists);
                setSelectedArtists(newSelectedArtists);
                setSelectArtistCount(prevDict => ({... prevDict, [teamNumber]: prevDict[teamNumber] - 1}));
                break;
            }
        }
    }

/*    const handleSort = (sortOption) => {
        setItems(items.sort((a, b) => {
          if (sortOption === 'name') {
            return a.name.localeCompare(b.name);
          } else if (sortOption === 'age') {
            return a.age - b.age;
          } else if (sortOption === 'city') {
            return a.city.localeCompare(b.city);
          }
          return 0;
        }));
      };*/

    const artistSelection = (e, index, artistUuid, teamNumber) => {
        if (!userService.isLoggedIn()) {
            setErrorMsg("Log in to get started...");
            setErrorHref("/login");
            setShowErrorDialog(true);
            return;
        }

        // TODO: check if can select artist or not
        let paymentStatus = ("user_details" in game && teamNumber in game["user_details"]) ? game["user_details"][teamNumber]["payment_status"] : null;
        if (paymentStatus === "PROCESSED" || paymentStatus === "CREATED" || paymentStatus === "PAID") {
            setErrorMsg("You cannot modify your team after making payment");
            setShowErrorDialog(true);
            return;
        }

        let now_time = new Date();
        let particpation_time = new Date(game["details"]["participation_time"]);
        if (particpation_time < now_time) {
            setErrorMsg("The deadline to participate in this game has ended. Please check other upcoming games.");
            setShowErrorDialog(true);
            return;
        }

        const gameMinLevel = "min_level" in game["details"] && game["details"]["min_level"] > 0 ? game["details"]["min_level"] : 1;
        const gameLocked = gameMinLevel > userLevel;
        if (gameLocked) {
            setErrorMsg("Unlock at Level " + gameMinLevel.toString() + " to play.");
            setShowErrorDialog(true);
            return;
        }

        if (teamNumber === 3 && GAME_THIRD_TEAM_CREATE_LEVEL > userLevel) {
            setErrorMsg("Unlock extra team creation at Level " + GAME_THIRD_TEAM_CREATE_LEVEL.toString());
            setShowErrorDialog(true);
            return;            
        }

        const exists = selectedArtists[teamNumber].some((selectedArtist) => {
            return selectedArtist["uuid"] === artistUuid;
        });
        if (exists) {
            //event to drop
            dropFromTeam(index, teamNumber);
        } else {
            //event to add
            addToTeam(index, teamNumber);
        }
    }
//    const [dropdownOpen, setDropdownOpen] = useState(false);
  //  const toggle = () => setDropdownOpen((prevState) => !prevState);

//    const handleSortChange = (e) => {
  //      setSortOption(e.target.value);
    //}

    const imageStyles = {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
    };

    const checkboxStyles = {
        width: '20px',
        height: '20px',
//        appearance: 'none',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '4px',
        cursor: 'pointer',
        position: 'relative',
    };

    const checkedCheckboxStyles = {
        ...checkboxStyles,
        backgroundColor: '#4CAF50',
        border: '1px solid #4CAF50',
    };

    const artistClicked = (e, artistSocialMediaHandle) => {
        e.preventDefault();
        navigate('/creator/' + artistSocialMediaHandle);
    }    

    const teamLayout = (team_number) => {
        let paymentStatus = ("user_details" in game && team_number in game["user_details"]) ? game["user_details"][team_number]["payment_status"] : null;
        const teamSelected = (paymentStatus === "PROCESSED" || paymentStatus === "CREATED" || paymentStatus === "PAID");
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        const isUpcoming = start_time > now_time;

        return (
            <div>
                <Col className='ml-auto mr-auto text-center'>
                    {!teamSelected && <p>Select {set_size[team_number]} Creators to create your team<br></br><strong>({selectArtistCount[team_number]} out of {set_size[team_number]} selected)</strong>
                    </p> }
                    {teamSelected && <p style={{color:"green", fontWeight:"bold"}}>Your team is ready! Monitor your live rank and score on the Leaderboard.
                    </p> }
                    </Col>

                    <Col style={{ padding: "0px" }}>

                    <div style={{ overflow: "scroll" }}>
                        <table className="table" style={{ padding: "5px" }}>
                            <thead>
                            <tr style={{ color: "#000000" }}>
                                <th scope="col" style={{ padding: "7px" }}>Select</th>
                                <th scope="col" style={{ padding: "7px" }}>Creator</th>
                                <th scope="col" style={{ padding: "7px" }}>Name</th>
                                <th scope="col" style={{ padding: "7px" }}>Score</th>
                            </tr>
                            </thead>
                            <tbody>
                            {team_number in artists && artists[team_number].map((artist, index) => {

                                let artist_engagement = artist.engagement_rating;
                                if (isUpcoming === true) {
                                    artist_engagement = artist.avg_followers > 0 ? (artist.avg_likes + 2*artist.avg_comments) / (artist.avg_followers) * 100 : 0;
                                }
                                if (artist_engagement === undefined || artist_engagement === null || artist_engagement === NaN) {
                                    artist_engagement = 0;
                                }
                                let selected_percentage = 0;
                                if (topParticipants.length > 0) {
                                    selected_percentage = (artist.selected_count/topParticipants.length)*100;
                                }

                                    return <tr>
                                         {/*// onClick={(e) => artistSelection(e, index, artist.uuid)}>*/}
                                         <td >
                                            <input type="checkbox"
                                                   style={artist.selected ? checkedCheckboxStyles : checkboxStyles}
                                                   checked={artist.selected}
                                                   onClick={(e) => artistSelection(e, index, artist.uuid, team_number)}/>
                                        </td>
                                        <td scope="row">
                                            <img
                                                alt="Con10Craze Fantasy Creators"
                                                style={imageStyles}
                                                src={artist["profile_img"]}
                                                onClick={(e) => artistClicked(e, artist.social_media_handle)}
                                            ></img>
                                            <p style={{ fontWeight: "lighter", fontSize: "12px", textDecoration: "underline" }}
                                                onClick={(e) => artistClicked(e, artist.social_media_handle)}>More Details</p>
                                        </td>
                                        <td>
                                            <a className="artist-name"
                                                    href={"https://instagram.com/" + artist.social_media_handle}
                                                    target="_blank" rel="noopener noreferrer"
                                                    style={{
                                                        display: "flex",
                                                        fontSize: "initial",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        background: `linear-gradient(45deg, #405de6, #5851db, #833AB4, #c13584, #E1306c, #fd1d1d)`,
                                                        backgroundClip: "text",
                                                        color: "transparent"
                                                    }}>
                                                {artist.name}</a>
                                            <div style={{color:"grey"}}>Sel by {selected_percentage.toFixed(2)}%</div>
                                        </td>
                                        <td>
                                            <div style={{ animation: "blink 1.5s infinite", color: "green"}} >{artist_engagement.toFixed(2)}</div>
                                            <div style={{ fontSize: "0.75rem" }}>({formatter.format(artist.avg_followers)} Followers)</div>
                                        </td>
                                    </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </div>
        )
    }

    const newArtistLayout = () => {
        return (
            <>
                <div className="tab-row-container" style={{ display: 'flex', border: '1px solid grey', width: 'fit-content', margin: 'auto', borderRadius: '15px' }}>
                    {Array.from({ length: parseInt(teamsAllowed) }, (_, i) => (
                        <>
                            <NavItem key={i} style={{ display: 'flex', alignItems: 'center' }}>
                                <NavLink
                                    style={{
                                        color: "black",
                                        backgroundColor: teamPills === i + 1 ? "#FF6961" : "#dddddd", // Active tab different shade
                                        borderRadius: i === 0 ? "15px 0 0 15px" : i === teamsAllowed - 1 ? "0 15px 15px 0" : "0", // Rounded corners only for the first and last tab
                                        padding: "10px 15px",
                                        cursor: teamAllowedIndex[i] ? "pointer" : "not-allowed", // Enable or disable based on the variable
                                        opacity: teamAllowedIndex[i] ? 1 : 0.6, // Add opacity if disabled
                                        // borderLeft: i === 0 ? "1px solid grey" : "none", // Left border for the first tab
                                        // borderRight: i === teamsAllowed - 1 ? "1px solid grey" : "none", // Right border for the last tab
                                    }}
                                    className={teamPills === i + 1 ? "active" : ""}
                                    onClick={(e) => teamAllowedIndex[i] && handleTeamTabChange(e, i + 1)} // Only allow clicking if enabled
                                >
                                    <i className="now-ui-icons users_single-02"></i>
                                    Team {i + 1}
                                </NavLink>
                            </NavItem>
                            {i < teamsAllowed - 1 && <div style={{ width: "1px", backgroundColor: "grey", height: "auto" }} />} {/* Divider */}
                        </>
                    ))}
                </div>

                <CardBody>
                    <TabContent
                        className="text-center"
                        activeTab={"teamPills" + teamPills.toString()}
                    >
                        {Array.from({ length: parseInt(teamsAllowed) }, (_, i) => (
                            <TabPane key={i} tabId={"teamPills" + (i + 1).toString()}>
                                {teamLayout(i + 1)}
                            </TabPane>
                        ))}
                    </TabContent>
                </CardBody>
            </>
        );



    }

    const winningsLayout = (gameStarted, prizeMoneyToDistribute) => {
        // get winning dsitrbution from game
        let winningDistributionView  = [];
        if (game["details"]["winning_distribution_amounts"]) {
            winningDistributionView = game["details"]["winning_distribution_amounts"];
        } else {
            let distribution = game["details"]["winners_users_distribution"].split`,`.map(x=>+x)
            for ( let i = 0;i < distribution.length; ++i) {
                winningDistributionView.push(distribution[i] + "%")
            }
        }

        return (
            <Row style={{ justifyContent: "center", textAlign: "center", color: "#B00610" }}>
                <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#B00610"
                }}
                    onClick={(e) => {
                        setWinningDistribution(winningDistributionView)
                        setWinningDetails(true);
                    }}> WINNING DISTRIBUTION</p>

                {!gameStarted && 
                <>
                <InfoIcon className="infoIcon" id="engagementTooltipPrize"
                    style={{
                        marginLeft:"2%",
                        color: "grey",
                        fontSize: "12px",
                        scale: "1.5"
                    }}>
                </InfoIcon>
                <Tooltip
                    placement='top'
                    isOpen={tooltipOpenPrize}
                    target="engagementTooltipPrize"
                    toggle={toggleTooltipPrize}
                >The actual winning amount will depend on the final participant count after the game begins.
                </Tooltip></>}
            </Row>
        )


    }

    const handlePlayground = (e) => {
        e.preventDefault();
        navigate("/playground/" + game["details"]["uuid"]);
    }
    const artistsWinningsLayout = (gameStarted, prizeMoneyToDistribute) => {
        // get winning dsitrbution from game
        let winningDistributionView  = [];
        if (game["details"]["artists_winning_distribution_amounts"]) {
            winningDistributionView = game["details"]["artists_winning_distribution_amounts"];
        } else if (game["details"]["winners_artists_distribution"] === undefined || game["details"]["winners_artists_distribution"] === null
            || game["details"]["winners_artists_distribution"].trim().length === 0) {
            return (
                <></>
            )
        } else {
            let distribution = game["details"]["winners_artists_distribution"].split`,`.map(x=>+x)
            for ( let i = 0;i < distribution.length; ++i) {
                winningDistributionView.push(distribution[i] + "%")
            }
        }

        return (
            <Row style={{ justifyContent: "center", textAlign: "center", }}>
                <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#B00610"
                }}
                    onClick={(e) => {
                        setArtistWinningDistribution(winningDistributionView)
                        setArtistWinningDetails(true);
                    }}> CREATORS WINNING DISTRIBUTION</p>

                {!gameStarted && 
                <>
                <InfoIcon className="infoIcon" id="artistEngagementTooltipPrize"
                    style={{
                        marginLeft:"2%",
                        color: "grey",
                        fontSize: "12px",
                        scale: "1.5"
                    }}>
                </InfoIcon>
                <Tooltip
                    placement='top'
                    isOpen={artisttooltipOpenPrize}
                    target="artistEngagementTooltipPrize"
                    toggle={toggleArtistsTooltipPrize}
                >The actual winning amount will depend on the final participant count after the game begins.
                </Tooltip></>}
            </Row>
        )


    }

    const user_leaderboardLayout = () => {
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        if (!topParticipants || topParticipants.length === 0) {
            if (start_time > now_time) {
                return (
                    <div>Leaderboard would be updated once the game begins!</div>
                )
            } else {
                return (
                    <div>Leaderboard would be updated soon!</div>
                )
            }
        }
        let showScore = true

        if ( start_time > now_time ) {
            showScore = false
        }
        return (
            <Row className="leaderboard-wrapper">
                <Table id="rankings" className="leaderboard-results" width="100%" rules="row">
                    <thead>
                    <tr>
                        <th>
                            Rank
                        </th>
                        <th>
                            User
                        </th>
                        {showScore && <th>
                            Score
                        </th>}
                        <th>
                            Team Created
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {topParticipants.map((rowData, index) => {
                        return (
                            <tr>
                                <td scope="row">
                                    {index + 1}
                                </td>
                                <td style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}
                                    onClick={(e) => navigate('/user/' + rowData.uuid)}>
                                    {rowData.first_name}
                                    {"level" in rowData && rowData.level > 0 && <div className="leaderboard-level-name">
                                         {`Level ${rowData.level}`}
                                    </div>}
                                    <div className="team-name">
                                        {`Team   ${rowData.team_number}`}
                                    </div>
                                </td>
                                {showScore && <td>
                                    {parseFloat(rowData.score).toFixed(2)}
                                </td>
                                }
                                <td>
                                    {format(rowData.team_created_at, 'dd MMMM h:mm a')}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
                </Table>
            </Row>
        )
    }

    const artist_row = (rowData, index, showScore) => {
        var class_name = index===0?"table-success" : (index === 1? "table-warning" : ( index ===2 ? "table-light" : "table-light"))
        return (
            <tr>
                <td className={`-default ${class_name}`} >
                    {index + 1}
                </td>
                <td className={`-default ${class_name}`} >

                    <a className="artist-name"
                       href={"https://instagram.com/" + rowData.social_media_handle}
                       target="_blank" rel="noopener noreferrer"
                    > {rowData.name} </a>
                </td>
                {showScore &&                <td className={`-default ${class_name}`} >

                {rowData.engagement_rating.toFixed(2)}%
                </td>}
                <td className={`-default ${class_name}`} >

                {rowData.select_by_user_count}
                </td>
            </tr>
        )

    }
    const artist_leaderboardLayout = () => {
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        if (!artistsRanking || artistsRanking.length === 0) {
            return (
                <div>Leaderboard would be updated soon!</div>
            )
        }
        let showScore = true

        if ( start_time > now_time ) {
            showScore = false
        }
        return (
            <Row >
                <Table id="rankings" className="table table-bordered" width="100%">
                    <thead>
                    <tr>
                        <th scope="col">
                            #
                        </th>
                        <th scope="col">
                            Artist
                        </th>
                        {showScore && <th scope="col">
                            Engagement Rate
                        </th>}
                        <th scope="col">
                            Selected by users
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {artistsRanking.map((rowData, index) => {
                      return  artist_row(rowData,index,showScore)
                    })}
                    </tbody>
                </Table>
            </Row>
        )
    }


    const details = () => {
        if ("start_time" in game["details"] && "end_time" in game["details"]) {
            let users = game["details"]["max_users"];
            let gameStarted = false;
            if (game["status"] !== "upcoming") {
                users = game["details"]["interested"];
                gameStarted = true;
            }

            let prizeDistribution = Math.floor(users * game["details"]["price"] * game["details"]["winners_users_percent"] / 100);
            return (
                <div color="info" style={{  boxShadow:"0 4px 6px -4px rgba(0, 0, 0, 0.1)",border:"2px solid #dddddd",
                    borderRadius: "20px", padding: "50px 20px", }}>
                    <Col className='ml-auto mr-auto text-center'>
                        <p style={{ fontWeight: "normal" }}>Use a creator's engagement rating statistic to decide your team.</p>
                        <p style={{ fontWeight: "normal" }}>Your score will be updated periodically throughout the course of the game, so keep an eye on the leaderboard to track your latest rank.</p>
                        <p style={{ color: "black", fontWeight: "bold" }}>Game Timings<p style={{ color: "red" }}> {format(new Date(game["details"]["start_time"]), "do MMMM h:mm a")} to {format(new Date(game["details"]["end_time"]), "do MMMM h:mma")}</p></p>
                        {/*                        <p style={{fontWeight:"bold",color:"black"}}>Boosters Allowed<p style={{color:"red"}}> {maxBooster} {maxBooster > 1 ? "boosters" : "booster"}</p></p> */}
                        { !gameStarted && <p style={{ fontWeight: "bold", color: "black" }}>Maximum Participants<p style={{ color: "red" }}>{game["details"]["max_users"]}</p></p>
                        }
                        { gameStarted &&
                            <p style={{ fontWeight: "bold", color: "black" }}>Number of Player playing<p style={{ color: "red" }}>{game["details"]["interested"]}</p></p>
                        }


                        <p style={{ fontWeight: "bold", color: "black" }}>Cash Prize</p>
                        <p style={{color: "red", justifyContent:"center"}} > ₹{prizeDistribution}</p>
                        <Row className="text-center justify-content-center">
                            <p style={{ fontWeight: "bold", color: "black" }}>Content Start Time <p style={{ color: "red" }}>{format(new Date(game["details"]["posts_start_time"]), "do MMMM h:mm a")}</p></p>
                            <p style={{ fontWeight: "bold", color: "black" }}>Content End Time<p style={{ color: "red" }}>{format(new Date(game["details"]["end_time"]), "do MMMM h:mm a")}</p></p>
                        </Row>

                        {winningsLayout(gameStarted, prizeDistribution)}
                        {showPlayersLeaderboard && artistsWinningsLayout(gameStarted, prizeDistribution)}
                    </Col>
                </div>
            )
        }
    }

    function playgroundView() {
        let now_time = new Date();
        let start_time = new Date(game["details"]["start_time"]);
        const isUpcoming = start_time > now_time;


        return !isUpcoming && <div className="bottom-right-icon" onClick={handlePlayground}>
            <span className="icon-text" style={{color:"white", fontSize:"10px"}}>LIVE</span>
            {/*<LiveTvIcon style={{fontSize:"40px", color:"black"}} className="blinking-icon"  />*/}
            <span className="icon-text" style={{color:"black", fontSize:"10px"}}>PLAYGROUND</span>
        </div>

    }

    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                {showConfetti && <Confetti height={5000} />}
                <FantasyGameHader game={game} topParticipants={topParticipants} title={title} url={url} shareGame={shareGame} />
                <div className="main"></div>

                <div className="section section-tabs" id="tabs-elements">
                    <SEO
                        title="Play Con10Craze Fantasy Sports Games: Create Dream Teams, Engage with Artists, Earn Real Money!"
                        description="Join Con10Craze's Fantasy Sports Games and dive into a world where you craft dream teams of artists and influencers. Earn real money by achieving the best engagement scores. Let the games begin!"
                        type="website"
                        url={"https://con10craze.com/game/" + game_external_id} />


                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto">

                                { showPodium && <Row>
                                    {showWinners()}
                                </Row>
                                }

                                <Nav className="nav-tabs-neutral justify-content-center" data-background-color="blue" role="tablist" tabs>
                                    <NavItem>
                                        <NavLink
                                            className={iconPills === "1" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "1")}
                                        >
                                            <i className="now-ui-icons users_single-02"></i>
                                            My Teams
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={iconPills === "2" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "2")}
                                        >
                                            <i className="now-ui-icons sport_trophy"></i>
                                            Leaderboard
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={iconPills === "3" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "3")}
                                        >
                                            <i className="now-ui-icons travel_info"></i>
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    { showPlayersLeaderboard &&    <NavItem>
                                        <NavLink
                                            className={iconPills === "4" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "4")}
                                        >
                                            <i className="now-ui-icons travel_info"></i>
                                            Players Ranking
                                        </NavLink>
                                    </NavItem>}
                                    <NavItem>
                                        <NavLink
                                            className={iconPills === "5" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "5")}
                                        >
                                            <i className="now-ui-icons business_chart-bar-32"></i>
                                            Scorecard
                                        </NavLink>
                                    </NavItem>
                                    { !isUpcoming && <NavItem>
                                        <NavLink
                                            className={iconPills === "6" ? "active" : ""}
                                            onClick={(e) => handleTabChange(e, "6")}
                                        >
                                            <i className="now-ui-icons media-1_button-play"></i>
                                            Live Playground
                                        </NavLink>
                                    </NavItem>
                                    }
                                </Nav>

                                <CardBody>
                                    <TabContent
                                        className="text-center"
                                        activeTab={"iconPills" + iconPills}
                                    >
                                        <TabPane tabId="iconPills1">
                                            {newArtistLayout()}
                                        </TabPane>
                                        <TabPane tabId="iconPills2">
                                            {user_leaderboardLayout()}
                                        </TabPane>
                                        <TabPane tabId="iconPills3">
                                            {details()}
                                        </TabPane>
                                        { showPlayersLeaderboard && <TabPane tabId="iconPills4">
                                            {artist_leaderboardLayout()}
                                        </TabPane>}
                                        <TabPane tabId="iconPills5">
                                            <Scorecard game_external_id={game_external_id} />
                                        </TabPane>
                                        { !isUpcoming &&<TabPane tabId="iconPills6">
                                            <Playground />
                                        </TabPane>}
                                    </TabContent>
                                </CardBody>
                            </Col>
                        </Row>
                    </Container>

                    {popupNotifications()}

                    <MyModalDialog title="" show={showErrorDialog} onClose={() => { setShowErrorDialog(false); setErrorMsg(null) }}>
                        <Row><Col className="ml-auto mr-auto text-center">
                            {errorMsg && errorHref && <p><a href={errorHref}>{errorMsg}</a></p>}
                            {!errorMsg || !errorHref &&
                            <p style={{color:"#B00610", fontWeight:"bolder"}}>{errorMsg}</p>}
                        </Col></Row>
                    </MyModalDialog>


{/*                    {playgroundView()}*/}

                </div>
                <DarkFooter />
            </div>
        </>
    );
};

export default FantasyGamePage;
